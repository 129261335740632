
import axios from 'axios';
import { defineComponent, ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import Upload from '@/views/LocalRegistration/components/Upload.vue';
// import ApplicableExemptionCaseSelector from '@/views/LocalRegistration/components/ApplicableExemptionCaseSelector.vue';
import {
    createNewRuleCode,
    saveCalculationRule,
    updateCalculationRule,
} from '@/API/localRegistration';

enum Mode {
    VIEW,
    ADD,
    EDIT,
}

type UndefinedString = undefined | string;

export default defineComponent({
    components: {
        Upload,
        // ApplicableExemptionCaseSelector,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const ruleCode = ref('');
        const mode = ref(Mode.ADD);
        mode.value = route.params.mode === 'add' ? Mode.ADD : Mode.VIEW;
        const id = route.query.id as string;
        const bu = route.query.bu as string;

        const ruleName = ref<UndefinedString>(undefined);
        const ruleNameList = [
            '三方版',
            '行驶证版',
            '三方为主行驶证为辅版',
            '行驶证为主三方为辅版',
        ];

        const exemptionCase = ref<UndefinedString>(undefined);

        const registrationDateSource = ref<UndefinedString>(undefined);
        // const registrationDateSourceList = computed(() => {
        //     console.log('object', ruleName.value);
        //     if (!ruleName.value) return [];
        //     const maps = {
        //         三方版: [{ title: '三方', value: '1' }],
        //         行驶证版: [{ title: '行驶证', value: '2' }],
        //         三方为主行驶证为辅版: [{ title: '三方，行驶证', value: '3' }],
        //         行驶证为主三方为辅版: [{ title: '行驶证，三方', value: '4' }],
        //     };
        //     return maps[ruleName.value];
        // });
        const registrationDateSourceList = ref<any>([]);
        watch(
            ruleName,
            (newRuleName: any, oldRuleName: any) => {
                if (newRuleName !== oldRuleName) {
                    registrationDateSource.value = undefined;
                }
                if (!newRuleName) registrationDateSourceList.value = [];
                const maps = {
                    三方版: [{ title: '三方', value: '1' }],
                    行驶证版: [{ title: '行驶证', value: '2' }],
                    三方为主行驶证为辅版: [
                        { title: '三方，行驶证', value: '3' },
                    ],
                    行驶证为主三方为辅版: [
                        { title: '行驶证，三方', value: '4' },
                    ],
                };
                registrationDateSourceList.value = maps[newRuleName];
            },
            {
                immediate: true,
            }
        );

        const description = ref('');

        const setRuleCode = () => {
            if (mode.value === Mode.ADD) {
                createNewRuleCode().then((res: any) => {
                    ruleCode.value = res;
                });
            } else {
                ruleCode.value = route.query.code as string;
                ruleName.value = route.query.name as string;
                exemptionCase.value = route.query.exemptionCode as string;
                registrationDateSource.value = route.query.dataSource as string;
                description.value = route.query.description as string;
            }
        };
        setRuleCode();

        const breadcrumb = computed(() => {
            return mode.value === Mode.ADD ? ['Add'] : ['View'];
        });

        const handleToEdit = () => {
            mode.value = Mode.EDIT;
            console.log(
                'registrationDateSourceList',
                registrationDateSourceList.value
            );
        };

        const visibleUploadDrawer = ref(false);

        const handleToUploadVinList = () => {
            visibleUploadDrawer.value = true;
        };

        const handleCancel = () => {
            router.go(-1);
        };

        const save = () => {
            if (mode.value === Mode.ADD) {
                const params = [
                    {
                        bu,
                        ruleCode: ruleCode.value,
                        ruleName: ruleName.value,
                        dataSource: registrationDateSource.value,
                        description: description.value,
                        exemptionCode: exemptionCase.value,
                        specialDeduction: 'N',
                        usage: 'Y',
                    },
                ];
                saveCalculationRule(params).then((res: any) => {
                    if (res.length > 0) {
                        message.success('Save Successfully!');
                        router.go(-1);
                    }
                });
            } else {
                const params = {
                    bu,
                    id,
                    ruleCode: ruleCode.value,
                    ruleName: ruleName.value,
                    dataSource: registrationDateSource.value,
                };
                updateCalculationRule(params).then((res: any) => {
                    if (res === '') {
                        message.success('Save Successfully!');
                        router.go(-1);
                    }
                });
            }
        };

        const uploadVin = (file: any, resolve: any) => {
            const formData = new FormData();
            formData.append('file', file);
            const url = `/rvapi/lr/regCalRuleDeduction/import/uploadCalVinlist`;
            axios({
                method: 'post',
                data: formData,
                // import excel for exemption rule
                url,
            }).then((res: any) => {
                resolve();
            });
        };

        return {
            Mode,
            ruleCode,
            description,
            exemptionCase,
            mode,
            breadcrumb,
            handleToEdit,
            visibleUploadDrawer,
            handleToUploadVinList,
            handleCancel,
            ruleName,
            ruleNameList,
            registrationDateSource,
            registrationDateSourceList,
            save,
            uploadVin,
        };
    },
});
